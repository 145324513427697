<template>
  <main class="mr-4">
    <trac-back-button>Back</trac-back-button>
    <trac-loading v-if="isLoading" />
    <!-- <h3 class="my-8 font-bold text-sm">KYC</h3> -->

    <div
      class="shadow-lg rounded-md p-4 pt-8 md:w-9/12 mx-auto my-8 md:px-12 flex flex-col items-center py-16"
    >
      <h3 class="my-5 font-bold text-primaryBlue">Session Expired</h3>
      <p class="text-xs">
        Please enter your Traction password to resume your progress
      </p>
      <div class="my-10 w-full md:w-128">
        <trac-input
          v-model.trim="payload.password"
          type="password"
          placeholder="Password"
          specified="password"
          class="mt-6 w-full md:w-128"
          ref="password"
          tag="div"
        />
      </div>

      <trac-button
        :disabled="!formValid.password && !formValid.username"
        class="w-full max-w-xs my-12"
        @button-clicked="confirmPassword"
        ><div class="py-1">Resume</div></trac-button
      >
    </div>
  </main>
</template>

<script>
import {
  GET_USER_BUSINESS_DATA,
  SAVE_LOCAL_DB_DATA,
} from "../../../src/browser-db-config/localStorage";
import { eventBus } from "../../../src/eventBus";
import WalletToken from "./WalletToken";

export default {
  mixins: [WalletToken],
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    async confirmPassword() {
      this.isLoading = true;
      this.payload.username = GET_USER_BUSINESS_DATA().email
      const res = await this.$store.dispatch("WALLET_AUTH", {
        email: this.payload.username,
        password: this.payload.password,
      });
      if (res.status) {
        const token = res.data;
        SAVE_LOCAL_DB_DATA("wallet_token", token);
        this.$router.push({ name: "MainWallet" });
      } else {
        eventBus.$emit("trac-alert", {
          message: "Invalid Password",
        });
      }
      this.isLoading = false;
    },
  },
};
</script>

<style>
</style>